.c-json-view {
  margin: 0;
  .c-json-p {
    .c-json-key {
      font-size: 14px;
      color: #000000;
    }
    .c-json-number {
      color: #ee422e;
    }
    .c-json-string {
      color: #008000;
    }
    .c-json-null {
      color: #004ed0;
    }
    .c-json-boolean {
      color: #ff8c00;
    }
    .c-json-pt {
      color: #000000;
    }
    .c-json-comma {
      color: #000000;
    }
  }

  .c-json-p.c-line-del {
    background-color: #fbe9eb;
  }
  .c-json-p.c-line-add {
    background-color: #ecfdf0;
  }
}
