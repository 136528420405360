$primary: #1976d2;
$background: #ffffff;
$divider: rgba(0, 0, 0, 0.08);

// layout

.RaLayout-content {
  padding: 0 24px !important;
  border-left: 1px solid $divider;
  background-color: #F9FAFB !important;
  width: 0;
}

// fields

.RaImageField-image {
  width: auto !important;
  height: auto !important;
  min-width: 200px;
  min-height: 100px;
}

.key-modal-request {
  z-index: 1;
}
.react-datepicker-popper {
  z-index: 2000 !important;
}

.react-datepicker__tab-loop {
  position: absolute;
  top: 0;
}

// sidebar

.RaSidebar-fixed {
  overflow: visible !important;
  z-index: 2000;
}

.RaSidebar-docked {
  min-width: 64px;
}

.RaMenuItemLink-icon{
  margin-right: 16px;
  margin-left: -4px;
}

.MuiAppBar-root {
  padding: 0 !important;
  & .MuiToolbar-root {
    padding-left: 0;
  }
}

.MuiMenuItem-root {
  min-height: 48px !important;
  margin: 0 8px !important;
  color: rgba(0, 0, 0, 0.87) !important;
  white-space: pre-wrap !important;



  &:hover {
    background: $divider !important;
    border-radius: 4px !important;
  }
}

.RaMenuItemLink-active {

  background: rgba($primary, .08) !important;
  color: $primary !important;
  border-radius: 4px !important;

  svg {
    fill: $primary;
  }
}

.RaSidebar-appBarCollapsed {
  margin-top: 0 !important;
}

.MuiDrawer-paper{
  overflow: visible !important;
}

.MuiList-root {
  .MuiMenuItem-root {
    min-height: 36px;
    font-size: 14px;
  }
}

.MuiCollapse-root {
  .MuiMenuItem-root {
    min-height: 36px !important;
  }
}

.RaSidebar-closed {

  .RaMenuItemLink-active {

    color: $primary !important;
    background: none !important;

    svg {
      fill: $primary;
    }
  }

  .MuiMenuItem-root {
    &:hover {
      background: $divider !important;
      border-radius: 50% !important;
    }
  }
}


// List

.RaList-main {
  width:100%;
}

.RaList-content {
  background-color: inherit !important;
}

// Datagrid

.RaDatagrid-root {
  position: relative;
  background: #FFF;
  border: 1px solid $divider;
  border-bottom: 0;
  border-radius: 4px 4px 0 0;
  overflow-x: auto;
  overflow-y: auto;

  > div:has(> .MuiToolbar-root) {
    position: sticky !important;
    top: 0;
    left: 0;
    z-index: 3;
  }

  .MuiTableCell-root {
    border-bottom-color: $divider;
  }
}

.RaBulkActionsToolbar-toolbar {
  position: static !important;
  transform: none !important;
  border-radius: 0 !important;
  background: #EEEEEE !important;

  .RaBulkActionsToolbar-topToolbar {
    padding: 0 !important;
  }
}

.RaBulkActionsToolbar-topToolbar {
  padding-top: 0 !important;
}

.MuiTablePagination-root {
  background-color: #FFFFFF !important;
  border: 1px solid $divider;
  border-top: 0;
  border-radius: 0 0 4px 4px;

  .MuiPagination-ul {
    gap: 6px
  }
}

.MuiTablePagination-toolbar {
  min-height: 0 !important;
}

// fix for time only react-datepicker
.react-datepicker--time-only .react-datepicker__triangle {
  left: -45% !important;
}

//Buttons
.ra-delete-button {
  padding: 0 16px !important;
  height: 36px !important;
}

//fix popper shadows
.MuiAutocomplete-popper {
  border-radius: 4px;
  box-shadow: 0 3px 14px 2px #0000001F,  0 8px 10px 1px #00000024, 0 5px 5px -3px #00000033;
}

.MuiPopover-root {
  .MuiPopover-paper {
    border-radius: 4px;
    box-shadow: 0 3px 14px 2px #0000001F,  0 8px 10px 1px #00000024, 0 5px 5px -3px #00000033;
  }
}

