.gatewayMethodsDatePicker {
  width: 160px !important;
  height: 56px;
  margin-right: 16px !important;
  margin-bottom: 24px !important;
  margin-top: 0 !important;
  & .MuiInputLabel-root {
    top: 8px;
  }
  & .MuiInputBase-input {
    height: 24px;
    width: 136px;
    padding: 16px 12px;
  }
  & .MuiInputLabel-shrink {
    top: 0;
  }
};

.gatewayMethodsDatePickerPopper {
  width: 330px !important;
};
