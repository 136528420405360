.twoFactor {
  &__app {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    &-link {
      position: relative;
      width: 150px;
      margin-bottom: 16px;
      margin-right: 10px;
      padding: 5px 10px 5px 45px;
      background: #000000;
      border-radius: 7px;
      color: #ffffff;
      text-decoration: none;
      &:hover {
        background: #313131;
      }
      &_google,
      &_apple {
        &:before {
          content: "";
          position: absolute;
          top: 8px;
          left: 10px;
          width: 26px;
          height: 28px;
          background: url(../../images/app_google_apple.png) no-repeat;
          background-size: cover;
        }
      }
      &_google:before {
        background-position: 0 0;
      }
      &_apple:before {
        background-position: 0 -29px;
      }
    }
    &-text {
      display: block;
      font-size: 10px;
    }
    &-name {
      font-size: 17px;
      line-height: 17px;
    }
  }
  &__qr {
    display: flex;
    justify-content: center;
    margin-bottom: 16px;
  }
}
